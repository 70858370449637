import React, { useEffect } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { reCaptchaKey } from "../../../../components/Variables"
import { downloadReportView } from "../../../../components/Statuses/Statuses"

import Layout from "../../../../components/Layout/Layout"
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs"
import HeaderImage from "../../../../assets/img/new/tlo-serch-01.webp"
import HubSpotForm from "../../../../components/HubSpotForm"

import { devMode } from "../../../../components/Variables"

import "../../../../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const Download = props => {
  const id = props.params.id
  const { t } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage("en")
    downloadReportView(id)
  }, [id])

  return (
    <Layout
      seo={{
        title: "Your SEO audit report • Download the website’s audit result for free • WeNet.pl",
        description:
          "Fill in the form and download the SEO audit report for free • See how you can improve your website",
        hreflangs: {
          pl: "audyt/" + id + "/pobierz/",
          en: "en/audit/" + id + "/download/",
          de: "de/audit/" + id + "/herunterladen/",
        },
        robots: "noindex, nofollow",
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: "/audyt/" + id + "/pobierz",
                text: t("link-text-download-report"),
              },
            ]}
          />
        </div>
        <h1 className="title text-center">Download the SEO audit report for free</h1>
      </div>

      <section className="standard-section contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="dowload-text hubspot-success-hide" style={{ marginTop: "30px" }}>
                After filling out this form, you will receive an e-mail message containing a complete, comprehensive SEO
                audit report of your website in PDF format. The report contains detailed descriptions for measured
                criteria, which will help you to better understand the search engine’s expectations and optimise your
                website.
              </p>
            </div>
            <div className="col-md-12">
              <div style={{ maxWidth: "535px", margin: "40px auto 0 auto" }}>
                <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
                  <HubSpotForm
                    formId={
                      devMode === "true"
                        ? "9c521fb6-7765-4808-95c7-f03acba2ddb8"
                        : "099919df-8a0e-42e0-b978-d0770cf95a88"
                    }
                    formType="download"
                    auditId={id}
                    userWWW={props?.location?.state?.userWWW ? props.location.state.userWWW : undefined}
                  />
                </GoogleReCaptchaProvider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Download
